<template lang="pug">
EditForm(
  @onsave="handleAdd"
  :formFields="formFields")
</template>

<script>
import { mapActions } from 'vuex'
import EditForm from '../components/EditForm.vue'
import { formFieldList } from '../config'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'UsewaterAdd',
  components: { EditForm },
  data () {
    return {
      formFields: cloneDeep(formFieldList)
    }
  },
  methods: {
    ...mapActions('tagsView', ['delView']),
    handleAdd ({ data, resolve, reject }) {
      data.meter.installDate = this.$day(new Date()).format('YYYY-MM-DD')
      this.$post({
        url: '/usewater/save',
        data
      })
        .then(res => {
          if (!res) {
            reject(res)
            return
          }
          resolve('success')
          this.$message.success('新增成功!')
          const view = cloneDeep(this.$route)
          this.$router.push({
            path: '/file/usewaterManage'
          })
          window.globalEvent.emit('delView', view)
          this.$nextTick(() => {
            window.globalEvent.emit('renderUsewaterTable')
          })
        })
        .catch(reject)
    }
  }
}
</script>
